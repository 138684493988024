// src/components/AboutKyrgyzstan.js
import React, { useContext } from "react";
import { ContentContext } from "../contexts/ContentContext";

const AboutKyrgyzstan = () => {
    const { data } = useContext(ContentContext);
    const content = data.aboutKyrgyzstan;
    const imagePath = `/images/${content.image}`;

    return (
        <div className="bg-white p-8">
            <h2 className="text-2xl font-bold mb-4">{content.title}</h2>
            <p>{content.content}</p>
            <div className="my-6">
                <img
                    src={imagePath}
                    alt="Kyrgyzstan"
                    className="w-full h-64 object-cover rounded-lg shadow-md"
                />
            </div>
        </div>
    );
};

export default AboutKyrgyzstan;
