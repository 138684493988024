// src/components/Footer.js
import React from "react";

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white p-4 mt-8">
            <div className="container mx-auto text-center">
                <p>&copy; 2024 North Star LLC. All rights reserved.</p>
                <p>
                    Email:{" "}
                    <a
                        href="mailto:info@northstaragro.com"
                        className="text-blue-400"
                    >
                        info@northstaragro.com
                    </a>
                </p>
                <p>
                    Call:{" "}
                    <a href="tel:+996504123088" className="text-blue-400">
                        +996504123088
                    </a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
