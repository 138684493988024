// src/components/FuturePerspective.js
import React, { useContext } from "react";
import { ContentContext } from "../contexts/ContentContext";

const FuturePerspective = () => {
    const { data } = useContext(ContentContext);
    const content = data.futurePerspective;

    return (
        <div className="bg-white p-8">
            <h2 className="text-2xl font-bold mb-4">{content.title}</h2>
            <p>{content.content}</p>
        </div>
    );
};

export default FuturePerspective;
