// src/pages/ContactPage.js
import React from "react";
import ContactUs from "../components/ContactUs";

const ContactPage = () => {
    return (
        <div className="container mx-auto p-8">
            <ContactUs />
        </div>
    );
};

export default ContactPage;
