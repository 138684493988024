import React, { useContext } from "react";
import { ContentContext } from "../contexts/ContentContext";

const DatesInSaudiArabia = () => {
    const { data } = useContext(ContentContext);
    const content = data.datesInSaudiArabia;

    return (
        <section className="bg-white p-8 md:p-16">
            <div className="container mx-auto flex flex-wrap items-center">
                <div className="w-full lg:w-1/2 mb-8 lg:mb-0">
                    <h2 className="text-3xl md:text-4xl font-bold mb-4">
                        {content.title}
                    </h2>
                    <p className="text-base md:text-lg mb-4">
                        {content.content}
                    </p>
                </div>
                <div className="w-full lg:w-1/2 flex flex-wrap">
                    {content.images.map((image, index) => {
                        const imagePath = `/images/${image}`;
                        return (
                            <div key={index} className="w-full md:w-1/2 p-2">
                                <img
                                    src={imagePath}
                                    alt="Date Farm"
                                    className="w-full h-48 md:h-64 object-cover rounded-lg shadow-md"
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
};

export default DatesInSaudiArabia;
