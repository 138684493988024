// src/pages/AboutPage.js
import React from "react";
import AboutKyrgyzstan from "../components/AboutKyrgyzstan";
import OurWorkInKyrgyzstan from "../components/OurWorkInKyrgyzstan";
import FuturePerspective from "../components/FuturePerspective";

const AboutPage = () => {
    return (
        <div>
            <AboutKyrgyzstan />
            <OurWorkInKyrgyzstan />
            <FuturePerspective />
        </div>
    );
};

export default AboutPage;
