import React, { useContext } from "react";
import { ContentContext } from "../contexts/ContentContext";

const FumigationProcess = () => {
    const { data } = useContext(ContentContext);
    const content = data.fumigationProcess;

    return (
        <div className="bg-gray-100 p-8">
            <h2 className="text-2xl font-bold mb-4 text-center">
                {content.title}
            </h2>
            <p className="text-center mb-8">{content.content}</p>
            <div className="flex justify-center">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full max-w-screen-xl">
                    {content.images.map((image, index) => (
                        <div key={index} className="w-full p-2">
                            <img
                                src={`/images/${image}`}
                                alt={`Fumigation Step ${index + 1}`}
                                className="w-full h-48 md:h-64 object-cover rounded-lg shadow-md"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FumigationProcess;
