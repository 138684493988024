import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ContentProvider } from "./contexts/ContentContext";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";

function App() {
    return (
        <ContentProvider>
            <Router>
                <div className="App flex flex-col min-h-screen">
                    <Header />
                    <div className="flex-grow pt-20">
                        {" "}
                        {/* Add pt-20 for padding */}
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/about" element={<AboutPage />} />
                            <Route path="/contact" element={<ContactPage />} />
                        </Routes>
                    </div>
                    <Footer />
                </div>
            </Router>
        </ContentProvider>
    );
}

export default App;
