// src/components/ContactUs.js
import React, { useContext } from "react";
import { ContentContext } from "../contexts/ContentContext";

const ContactUs = () => {
    const { data } = useContext(ContentContext);
    const content = data.contactUs;

    return (
        <div className="bg-white p-8">
            <h2 className="text-2xl font-bold mb-4">{content.title}</h2>
            <p>{content.content}</p>
            <ul className="list-disc ml-6 mt-4">
                <li>
                    Email:{" "}
                    <a
                        href={`mailto:${content.email}`}
                        className="text-blue-500"
                    >
                        {content.email}
                    </a>
                </li>
                <li>
                    Call:{" "}
                    <a href={`tel:${content.phone}`} className="text-blue-500">
                        {content.phone}
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default ContactUs;
