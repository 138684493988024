import React, { useContext } from "react";
import { ContentContext } from "../contexts/ContentContext";
import Chart from "react-apexcharts";

const StatisticsOnDates = () => {
    const { data } = useContext(ContentContext);
    const content = data.statisticsOnDates;

    const chartOptions1 = {
        chart: {
            type: "bar",
            height: "auto",
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "55%",
                endingShape: "rounded",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
        },
        xaxis: {
            categories: content.chartCategories,
        },
        yaxis: {
            title: {
                text: "Thousand Metric Tons",
            },
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + " Thousand Metric Tons";
                },
            },
        },
    };

    const chartOptions2 = {
        chart: {
            type: "bar",
            height: "auto",
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "55%",
                endingShape: "rounded",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
        },
        xaxis: {
            categories: content.chartCategories2,
        },
        yaxis: {
            title: {
                text: "Thousand Metric Tons",
            },
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + " Thousand Metric Tons";
                },
            },
        },
    };

    const chartSeries1 = [
        {
            name: "Production",
            data: content.chartData1,
        },
    ];

    const chartSeries2 = [
        {
            name: "Export",
            data: content.chartData2,
        },
    ];

    return (
        <div className="bg-white p-8">
            <h2 className="text-2xl font-bold mb-4">{content.title}</h2>
            <p>{content.content}</p>
            <div className="my-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="bg-gray-100 p-4 rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold mb-2">
                        Production Statistics
                    </h3>
                    <Chart
                        options={chartOptions1}
                        series={chartSeries1}
                        type="bar"
                        height={300}
                    />
                </div>
                <div className="bg-gray-100 p-4 rounded-lg shadow-md">
                    <h3 className="text-xl font-semibold mb-2">
                        Export Statistics
                    </h3>
                    <Chart
                        options={chartOptions2}
                        series={chartSeries2}
                        type="bar"
                        height={300}
                    />
                </div>
            </div>
            <p className="text-sm text-gray-600 mt-4">
                Source: FAO, Curated and published by NationMaster at:
                <a
                    href="https://www.nationmaster.com/nmx/timeseries/saudi-arabia-dates-production"
                    className="text-blue-500 underline mr-1 ml-1"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Dates Production in Saudi Arabia
                </a>
                <span>and</span>
                <a
                    href="https://www.nationmaster.com/nmx/timeseries/saudi-arabia-dates-export-quantity"
                    className="text-blue-500 underline ml-1"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Dates Export Volume in Saudi Arabia
                </a>
            </p>
        </div>
    );
};

export default StatisticsOnDates;
