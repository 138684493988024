import React, { createContext, useState, useEffect } from "react";
import content from "../content.json";

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
    const [language, setLanguage] = useState("en");
    const [data, setData] = useState(content[language]);

    useEffect(() => {
        setData(content[language]);
    }, [language]);

    return (
        <ContentContext.Provider value={{ data, setLanguage }}>
            {children}
        </ContentContext.Provider>
    );
};
