import React, { useContext } from "react";
import { ContentContext } from "../contexts/ContentContext";

const HealthBenefitsOfDates = () => {
    const { data } = useContext(ContentContext);
    const content = data.healthBenefitsOfDates;

    return (
        <section className="bg-gray-100 p-8 md:p-16">
            <div className="container mx-auto">
                <h2 className="text-3xl md:text-4xl font-bold mb-4">
                    {content.title}
                </h2>
                <ul className="list-disc list-inside text-base md:text-lg">
                    {content.benefits.map((benefit, index) => (
                        <li key={index} className="mb-2">
                            {benefit}
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default HealthBenefitsOfDates;
