import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ContentContext } from "../contexts/ContentContext";
import { FaBars, FaTimes } from "react-icons/fa";
import Select from "react-select";
import { components } from "react-select";
import { GB, RU, SA } from "country-flag-icons/react/3x2";

// Define language options with flag components
const languageOptions = [
    {
        value: "en",
        label: "En",
        icon: <GB title="United Kingdom" className="w-6 h-4" />,
    },
    {
        value: "ru",
        label: "Ru",
        icon: <RU title="Russia" className="w-6 h-4" />,
    },
    {
        value: "ar",
        label: "Ar",
        icon: <SA title="Saudi Arabia" className="w-6 h-4" />,
    },
];

const logoPath = `/images/logo.png`;

const Header = () => {
    const { setLanguage, data } = useContext(ContentContext);
    const content = data.header;

    const [currentLanguage, setCurrentLanguage] = useState("en");
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const handleLanguageChange = (selectedOption) => {
        const newLanguage = selectedOption.value;
        setLanguage(newLanguage);
        setCurrentLanguage(newLanguage);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Custom SingleValue component to include flag icon
    const SingleValue = (props) => (
        <components.SingleValue {...props}>
            <div className="flex items-center gap-1">
                {props.data.icon}
                <span>{props.data.label}</span>
            </div>
        </components.SingleValue>
    );

    // Custom Option component to include flag icon
    const Option = (props) => (
        <components.Option {...props}>
            <div className="flex items-center rounded-lg bg-green-600 pl-2 pr-2 text-white hover:bg-green-400 gap-2">
                {props.data.icon}
                <span>{props.data.label}</span>
            </div>
        </components.Option>
    );

    return (
        <header
            className={`bg-green-600 text-white p-6 fixed top-0 left-0 w-full z-50 shadow-md transition-transform duration-300 ease-in-out ${
                isScrolled ? "hidden" : ""
            }`}
        >
            <div className="container mx-auto flex justify-between items-center">
                <div className="flex items-center space-x-4">
                    <Link
                        to="/"
                        className="flex items-center space-x-2 hover:text-gray-200 transition-colors duration-300"
                    >
                        <img
                            src={logoPath}
                            alt="North Star LLC"
                            className="h-10"
                        />
                        <span className="text-xl font-bold">
                            North Star LLC
                        </span>
                    </Link>
                </div>
                <nav className="hidden md:flex space-x-6">
                    <Link
                        to="/"
                        className="hover:text-gray-200 transition-colors duration-300"
                    >
                        {content.home}
                    </Link>
                    <Link
                        to="/about"
                        className="hover:text-gray-200 transition-colors duration-300"
                    >
                        {content.about}
                    </Link>
                    <Link
                        to="/contact"
                        className="hover:text-gray-200 transition-colors duration-300"
                    >
                        {content.contact}
                    </Link>
                </nav>
                <div className="flex items-center">
                    <Select
                        value={languageOptions.find(
                            (option) => option.value === currentLanguage
                        )}
                        onChange={handleLanguageChange}
                        options={languageOptions}
                        components={{ SingleValue, Option }}
                        className="w-24"
                        classNamePrefix="react-select"
                        isSearchable={false}
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                backgroundColor: "#d3f9d8", // Light green background color
                                border: "none",
                                boxShadow: "none",
                            }),
                            singleValue: (provided) => ({
                                ...provided,
                                color: "#000", // Black text color
                            }),
                            menu: (provided) => ({
                                ...provided,
                                backgroundColor: "#d3f9d8", // Light green background color for dropdown
                            }),
                            option: (provided) => ({
                                ...provided,
                                backgroundColor: "#d3f9d8", // Light green background color for options
                                //     ? "#b0eac9"
                                //     : "#d3f9d8", // Change background on hover
                                borderRadius: "2rem", // Rounded corners
                                cursor: "pointer",
                            }),
                            menuList: (provided) => ({
                                ...provided,
                                padding: 0,
                            }),
                        }}
                    />
                    <button
                        onClick={toggleMenu}
                        className="md:hidden p-3 rounded hover:bg-green-700 transition-colors duration-300"
                        title="Menu"
                    >
                        {isMenuOpen ? (
                            <FaTimes className="text-2xl" />
                        ) : (
                            <FaBars className="text-2xl" />
                        )}
                    </button>
                </div>
            </div>
            {isMenuOpen && (
                <nav className="md:hidden bg-green-700 text-black p-4 space-y-4 rounded-lg absolute right-4 top-16 shadow-lg">
                    <Link
                        to="/"
                        className="block hover:text-gray-200 transition-colors duration-300"
                        onClick={toggleMenu}
                    >
                        {content.home}
                    </Link>
                    <Link
                        to="/about"
                        className="block hover:text-gray-200 transition-colors duration-300"
                        onClick={toggleMenu}
                    >
                        {content.about}
                    </Link>
                    <Link
                        to="/contact"
                        className="block hover:text-gray-200 transition-colors duration-300"
                        onClick={toggleMenu}
                    >
                        {content.contact}
                    </Link>
                </nav>
            )}
        </header>
    );
};

export default Header;
