import React, { useContext } from "react";
import { ContentContext } from "../contexts/ContentContext";

const ByproductsOfDates = () => {
    const { data } = useContext(ContentContext);
    const content = data.byproductsOfDates;

    return (
        <div className="bg-white p-8">
            <h2 className="text-center text-3xl md:text-4xl font-bold mb-8 text-gray-800">
                {content.title}
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {content.byproducts.map((byproduct, index) => (
                    <div
                        key={index}
                        className="bg-gray-100 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 hover:scale-105 transform"
                    >
                        <img
                            src={`/images/${byproduct.image}`}
                            alt={byproduct.name}
                            className="w-full h-64 object-cover rounded-t-lg"
                        />
                        <div className="p-4">
                            <h3 className="text-xl md:text-2xl font-bold text-center text-gray-900 mb-2">
                                {byproduct.name}
                            </h3>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ByproductsOfDates;
