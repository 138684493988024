import React, { useContext } from "react";
import { ContentContext } from "../contexts/ContentContext";

const AboutSaudiArabia = () => {
    const { data } = useContext(ContentContext);
    const content = data.aboutSaudiArabia;
    const imagePath = `/images/${content.image}`;

    return (
        <section className="relative bg-gray-100 text-center md:text-left">
            <div className="relative w-full h-96 md:h-[500px] lg:h-[600px] overflow-hidden">
                <img
                    src={imagePath}
                    alt="Saudi Arabia"
                    className="absolute inset-0 w-full h-full object-fill object-center"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center md:items-start p-4 md:p-8 lg:p-12 text-white">
                    <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">
                        {content.title}
                    </h2>
                    <p className="text-base md:text-lg lg:text-xl max-w-3xl mb-4">
                        {content.content}
                    </p>
                    <button className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                        Learn More
                    </button>
                </div>
            </div>
        </section>
    );
};

export default AboutSaudiArabia;
