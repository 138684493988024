// src/pages/HomePage.js
import React from "react";
import AboutSaudiArabia from "../components/AboutSaudiArabia";
import DatesInSaudiArabia from "../components/DatesInSaudiArabia";
import HealthBenefitsOfDates from "../components/HealthBenefitsOfDates";
import StatisticsOnDates from "../components/StatisticsOnDates";
import FumigationProcess from "../components/FumigationProcess";
import TypesOfDates from "../components/TypesOfDates";
import ByproductsOfDates from "../components/ByproductsOfDates";

const HomePage = () => {
    return (
        <div>
            <AboutSaudiArabia />
            <DatesInSaudiArabia />
            <HealthBenefitsOfDates />
            <StatisticsOnDates />
            <FumigationProcess />
            <TypesOfDates />
            <ByproductsOfDates />
        </div>
    );
};

export default HomePage;
